import React from "react"
import { graphql } from "gatsby"
import Button from "../components/Button"
import Container from "../components/Container"
import Hero from "../components/Hero"
import Markdown from "../components/Markdown"
import Section from "../components/Section"
import Site from "../components/Site"
import "../css/pages.scss"

const Page = ({data}) => {

  // Setup
  // -----

  const page = data.datoCmsPage


  // Styles
  // -------

  const siteStyles = page.accent === null ? null : {
    '--c-base-accent'      : page.accent.hex,
    // '--c-header-top-a-int' : page.accent.hex,
    '--c-btn-bg'           : page.accent.hex,
    '--c-btn-bg-int'       : page.accent.hex,
    '--c-btn-bg-active'    : page.accent.hex,
    '--c-btn-bg-disabled'  : page.accent.hex,
  }


  // Background
  // ----------

  const introBgFallback = null // [{ angle : 'to bottom', stack : [{ stops : ['var(--c-base-bg-alt)', 'var(--c-base-bg)'] }] }]
  const introBg         = page.image === null ? introBgFallback : [
    {
      opacity : 0.88,
      stack   : [
        { angle : 'to bottom', stops : ['var(--c-base-bg)', 'var(--c-base-bg)'] },
      ]
    },
    {
      opacity : 1,
      stack   : [
        { angle : 'to bottom', stops : ['transparent', 'var(--c-base-bg) 88%'] },
      ]
    },
    {
      blend : 'luminosity',
      stack : [
        { url : page.image.url },
        { angle : 'to bottom', stops : ['var(--c-base-bg)', 'var(--c-base-bg)'] },
      ]
    },
  ]


  // Output
  // ------

  return (
    <Site
      title     = {page.title}
      className = "hcc-page"
      style     = {siteStyles}
    >
      <article>
        <Hero
          type      = "fullscreen"
          content   = "auto"
          tag       = "header"
          container = "lg"
          className = "hcc-page-intro"
          bg        = {introBg}
        >
          <h1>{page.title}</h1>
          {page.subtitle && <p>{page.subtitle}</p>}
          {(page.buttonLabel && page.buttonLink) &&
            <div className="hcc-hero-cta break-sm">
              <Button label={page.buttonLabel} href={page.buttonLink} className="is-primary has-no-outline"/>
            </div>
          }
        </Hero>
        <Section className="hcc-page-content">
          <Container size="md">
            <Markdown
              tag       = "div"
              type      = {null}
              className = "has-styled-links"
              source    = {page.content}
            />
          </Container>
        </Section>
      </article>
    </Site>
  )
}

export default Page


// GraphQL
// -------

export const query = graphql`
  query PageQuerySingle($slug: String!) {
    datoCmsPage(slug: { eq: $slug }) {
      ...PageFragment
    }
  }
`
